import {
  OrganizationJsonLd,
  JsonLdTypes,
  FaqJsonLd,
  FaqQuestionJsonLd,
  ReviewJsonLd,
  ProductJsonLd,
  JsonLdConditionTypes,
  JsonLdInventoryTypes,
  ListItemJsonLd,
  ItemListJsonLd,
  BreadcrumbListJsonLd,
} from '@/shared/types/seo';
import { FAQType } from '@/shared/types/subscription';
import { PIETRA_LOGO_SQUARE, WEBSITE_URL } from '@/shared/constants';
import { CatalogItem, SupplierReview } from '../types/catalog';
import { convertReviewScoreToScale, getSupplierName } from '../utils/sourcing-marketplace-util';
import { convertToUTC } from '../utils/utils';

export function defaultPietraJsonLd(): OrganizationJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ORGANIZATION,
    name: 'Pietra',
    description: 'Pietra is an all in one membership for e-commerce brands to lower costs and grow their business.',
    logo: PIETRA_LOGO_SQUARE,
    url: 'https://www.pietrastudio.com',
  };
}

export function registerJsonLd() {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.REGISTER,
    object: {
      '@type': JsonLdTypes.ORGANIZATION,
      name: 'Pietra',
      url: 'https://pietra.store',
    },
  };
}

export function getReviewJsonLd(review: SupplierReview): ReviewJsonLd {
  return {
    '@type': JsonLdTypes.REVIEW,
    author: {
      '@type': JsonLdTypes.PERSON,
      name: review.buyerName,
    },
    reviewBody: review.content,
    reviewRating: {
      '@type': JsonLdTypes.RATING,
      ratingValue: convertReviewScoreToScale(review.score).toString(),
      worstRating: '1',
      bestRating: '5',
    },
    datePublished: convertToUTC(review.createdAt),
  };
}

export function faqJsonLd(faqs: FAQType[]): FaqJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.FAQ,
    mainEntity: faqAnswersJsonLd(faqs),
  };
}

export function faqAnswersJsonLd(faqs: FAQType[]): FaqQuestionJsonLd[] {
  return faqs.map((faq: FAQType) => {
    return {
      '@type': JsonLdTypes.QUESTION,
      name: faq.question,
      acceptedAnswer: {
        '@type': JsonLdTypes.ANSWER,
        text: faq.answer,
      },
    };
  });
}

export function getProductJsonLd(
  product: CatalogItem,
  reviewScore: number,
  reviewCount: number,
  reviews?: SupplierReview[],
): ProductJsonLd {
  const image = product?.images?.[0];
  const json: ProductJsonLd = {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.PRODUCT,
    name: product?.name,
    description: product.meta?.description,
    image,
    sku: product.id?.toString(),
    mpn: product.id?.toString(),
    offers: {
      '@type': JsonLdTypes.OFFER,
      url: `${WEBSITE_URL}/sourcing/products/${product.handle}`,
      sku: product.id,
      priceCurrency: 'USD',
      price: product.displayPrice,
      image,
      itemCondition: JsonLdConditionTypes.NEW,
      availability: JsonLdInventoryTypes.IN_STOCK,
    },
    brand: {
      '@type': JsonLdTypes.BRAND,
      name: getSupplierName(product.catalogCompany),
    },
  };
  if ((reviewScore || 0) > 0 && (reviewCount || 0) > 0) {
    json.aggregateRating = {
      '@type': JsonLdTypes.AGGREGATE_RATING,
      ratingValue: convertReviewScoreToScale(reviewScore).toString(),
      reviewCount: reviewCount.toString(),
    };
  }
  if (!reviews) {
    json.review = reviews?.map(getReviewJsonLd);
  }
  return json;
}

export function getProductListItemJsonLd(product: CatalogItem, index: number): ListItemJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.LIST_ITEM,
    position: index + 1,
    item: getProductJsonLd(
      product,
      product.catalogCompany?.companyReviewScore,
      product.catalogCompany?.companyReviewCount,
    ),
  };
}

export function getProductItemListJsonLd(products: CatalogItem[]): ItemListJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ITEM_LIST,
    itemListElement: products.map((product, index) => getProductListItemJsonLd(product, index)),
  };
}

export function getBreadcrumbListItemJsonLd(breadcrumb: { id: string; name: string }, index: number): ListItemJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.LIST_ITEM,
    position: index + 1,
    item: {
      '@type': JsonLdTypes.THING,
      id: breadcrumb.id,
      name: breadcrumb.name,
    },
  };
}

export function getBreadcrumbListJsonLd(breadcrumbs: { id: string; name: string }[]): BreadcrumbListJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.BREADCRUMB_LIST,
    itemListElement: breadcrumbs.map((breadcrumb, index) => getBreadcrumbListItemJsonLd(breadcrumb, index)),
  };
}
