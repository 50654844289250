export interface MetaDataProperty {
  vmid?: string;
  once?: boolean;
  skip?: boolean;
  body?: boolean;
  pbody?: boolean;
  [key: string]: any;
}

export interface MetaPropertyCharset extends MetaDataProperty {
  charset: string;
}

export interface MetaPropertyEquiv extends MetaDataProperty {
  httpEquiv: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface MetaPropertyName extends MetaDataProperty {
  name: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface MetaPropertyMicrodata extends MetaDataProperty {
  itemprop: string;
  content: string;
  template?: (chunk: string) => string;
}

// non-w3c interface
export interface MetaPropertyProperty extends MetaDataProperty {
  property: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface LinkPropertyBase extends MetaDataProperty {
  rel: string;
  crossOrigin?: string | null;
  media?: string;
  nonce?: string;
  referrerPolicy?: string;
  rev?: string;
  type?: string;
}

export interface LinkPropertyHref extends LinkPropertyBase {
  href?: string;
  hreflang?: string;
  callback?: void;
}

export interface LinkPropertyHrefCallback extends LinkPropertyBase {
  vmid: string;
  callback: () => void;
  href?: string;
  hreflang?: string;
}

export interface StyleProperty extends MetaDataProperty {
  cssText: string;
  callback?: () => void;
  media?: string;
  nonce?: string;
  type?: string;
}

export interface ScriptPropertyBase extends MetaDataProperty {
  type?: string;
  charset?: string;
  async?: boolean;
  defer?: boolean;
  crossOrigin?: string;
  nonce?: string;
}

export interface ScriptPropertyText extends ScriptPropertyBase {
  innerHTML: string;
}

export interface ScriptPropertySrc extends ScriptPropertyBase {
  src: string;
  callback?: void;
}

export interface ScriptPropertySrcCallback extends ScriptPropertyBase {
  vmid: string;
  callback: () => void;
}

type JsonVal = string | number | boolean | JsonObj | JsonObj[] | null;

interface JsonObj {
  [key: string]: JsonVal | JsonVal[];
}

export interface ScriptPropertyJson extends ScriptPropertyBase {
  json: JsonObj;
}

export interface AttributeProperty {
  [key: string]: string | string[];
}

export interface NoScriptProperty extends MetaDataProperty {
  innerHTML: string;
}

export interface MetaInfoOutput extends MetaInfo {
  titleChunk?: string;
}

export interface MetaInfo {
  title?: string;
  titleTemplate?: string | ((titleChunk: string) => string);

  htmlAttrs?: AttributeProperty;
  headAttrs?: AttributeProperty;
  bodyAttrs?: AttributeProperty;

  base?: {
    target: string;
    href: string;
  };

  meta?: (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[];
  link?: (LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback)[];
  style?: StyleProperty[];
  script?: (ScriptPropertyText | ScriptPropertySrc | ScriptPropertySrcCallback | ScriptPropertyJson)[];
  noscript?: NoScriptProperty[];

  __dangerouslyDisableSanitizers?: string[];
  __dangerouslyDisableSanitizersByTagID?: {
    [key: string]: string[];
  };

  changed?: <T extends MetaInfoOutput>(newInfo: T, addedTags: HTMLElement[], removedTags: HTMLElement[]) => void;
  afterNavigation?: <T extends MetaInfoOutput>(newInfo: T) => void;
}

export enum HeaderTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export interface pageMetadataArg {
  description?: string;
  image?: string;
  keywords?: string[];
  url?: string;
  script?: { src: string; type?: string }[];
  siteName?: string;
  title?: string;
}

export interface SeoHeader {
  tag: HeaderTag;
  text: string;
}

export interface jsonLdParam {
  plans?: any;
  product?: any;
  products?: any;
  store?: any;
  stores?: any;
  url?: string;
}

export enum SEO_META_PAGES {
  EXISTING_BRANDS = 'EXISTING_BRANDS',
  NEW_BRANDS = 'NEW_BRANDS',
  PIETRA_SHOPIFY = 'PIETRA_SHOPIFY',
  ERROR = 'ERROR',
  FULFILLMENT_SERVICES = 'FULFILLMENT_SERVICES',
  ORDER_POLICIES = 'ORDER_POLICIES',
  HOME = 'HOME',
  PIETRA_PARTNERS = 'PIETRA_PARTNERS',
  PRICING = 'PRICING',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  SELL_ON_PIETRA = 'SELL_ON_PIETRA',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',

  LANDING_START_CLOTHING_BRAND = 'LANDING_START_CLOTHING_BRAND',
  LANDING_START_ATHLEISURE_BRAND = 'LANDING_START_ATHLEISURE_BRAND',
  LANDING_START_AN_EYESHADOW_LINE = 'LANDING_START_AN_EYESHADOW_LINE',
  LANDING_START_A_HAIR_PRODUCTS_LINE = 'LANDING_START_A_HAIR_PRODUCTS_LINE',
  LANDING_START_A_LIP_GLOSS_LINE = 'LANDING_START_A_LIP_GLOSS_LINE',
  LANDING_START_A_MAKEUP_LINE = 'LANDING_START_A_MAKEUP_LINE',
  LANDING_START_A_SKINCARE_LINE = 'LANDING_START_A_SKINCARE_LINE',
  LANDING_START_A_CANDLE_LINE = 'LANDING_START_A_CANDLE_LINE',
  LANDING_START_A_ATHLEISURE_LINE = 'LANDING_START_A_ATHLEISURE_LINE',
  LANDING_START_A_JEWELRY_LINE = 'LANDING_START_A_JEWELRY_LINE',
  LANDING_START_A_SUNGLASSES_LINE = 'LANDING_START_A_SUNGLASSES_LINE',
  LANDING_START_A_LEATHER_GOODS_LINE = 'LANDING_START_A_LEATHER_GOODS_LINE',
  LANDING_FINDING_CLOTHING_SUPPLIERS = 'LANDING_FINDING_CLOTHING_SUPPLIERS',
  LANDING_FINDING_ATHLEISURE_SUPPLIERS = 'LANDING_FINDING_ATHLEISURE_SUPPLIERS',
  LANDING_OPEN_PIETRA_STOREFRONT = 'LANDING_OPEN_PIETRA_STOREFRONT',
  LANDING_ALTERNATIVE_TO_ETSY = 'LANDING_ALTERNATIVE_TO_ETSY',
  LANDING_ALTERNATIVE_TO_ALIBABA = 'LANDING_ALTERNATIVE_TO_ALIBABA',
  LANDING_ALTERNATIVE_TO_SHIPBOB = 'LANDING_ALTERNATIVE_TO_SHIPBOB',
  LANDING_ALTERNATIVE_TO_CHANNEL_ADVISOR = 'LANDING_ALTERNATIVE_TO_CHANNEL_ADVISOR',

  IDEATION = 'IDEATION',
  SOURCING = 'IDEATION',
  LOGISTICS = 'LOGISTICS',
  SALES = 'SALES',

  BUSINESS_KIT = 'BUSINESS_KIT',
  DESIGN_STUDIO = 'DESIGN_STUDIO',
}

export interface SeoMeta {
  [SEO_META_PAGES.EXISTING_BRANDS]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.PIETRA_SHOPIFY]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.NEW_BRANDS]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.ERROR]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.HOME]: {
    jsonld: () => any;
  };
  [SEO_META_PAGES.PIETRA_PARTNERS]: {
    jsonld: () => any;
  };
  [SEO_META_PAGES.PRICING]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.SELL_ON_PIETRA]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.TERMS_OF_SERVICE]: {
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.PRIVACY_POLICY]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.FULFILLMENT_SERVICES]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.ORDER_POLICIES]: {
    jsonld: (params: jsonLdParam) => any;
  };

  // Landing Pages
  [SEO_META_PAGES.LANDING_START_CLOTHING_BRAND]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_ATHLEISURE_BRAND]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_AN_EYESHADOW_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_HAIR_PRODUCTS_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_LIP_GLOSS_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_MAKEUP_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_SKINCARE_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_CANDLE_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_ATHLEISURE_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_JEWELRY_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_SUNGLASSES_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_START_A_LEATHER_GOODS_LINE]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_FINDING_CLOTHING_SUPPLIERS]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_FINDING_ATHLEISURE_SUPPLIERS]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_OPEN_PIETRA_STOREFRONT]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ETSY]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ALIBABA]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_CHANNEL_ADVISOR]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_SHIPBOB]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.IDEATION]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.SOURCING]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.LOGISTICS]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.SALES]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.BUSINESS_KIT]: {
    jsonld: (params: jsonLdParam) => any;
  };
  [SEO_META_PAGES.DESIGN_STUDIO]: {
    jsonld: (params: jsonLdParam) => any;
  };
}

export enum JsonLdTypes {
  ANSWER = 'Answer',
  AGGREGATE_OFFER = 'AggregateOffer',
  AGGREGATE_RATING = 'AggregateRating',
  BRAND = 'Brand',
  BREADCRUMB_LIST = 'BreadcrumbList',
  FAQ = 'FAQPage',
  ITEM_LIST = 'ItemList',
  LIST_ITEM = 'ListItem',
  OFFER = 'Offer',
  ONLINE_BUSINESS = 'OnlineBusiness',
  ORGANIZATION = 'Organization',
  PRICE_SPECIFICATION = 'UnitPriceSpecification',
  PERSON = 'Person',
  PRODUCT = 'Product',
  QUESTION = 'Question',
  RATING = 'Rating',
  REGISTER = 'RegisterAction',
  REVIEW = 'Review',
  THING = 'Thing',
  WEB_APP = 'WebApplication',
}

export enum JsonLdConditionTypes {
  NEW = 'https://schema.org/NewCondition',
}

export enum JsonLdInventoryTypes {
  IN_STOCK = 'https://schema.org/InStock',
  OUT_OF_STOCK = 'https://schema.org/OutOfStock',
}

export interface BaseJsonLd {
  '@context'?: 'https://schema.org';
  name?: string;
  description?: string;
}

export interface OrganizationJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ORGANIZATION;
  url?: string;
  logo?: string;
}

export interface PriceSpecificationJsonLd {
  '@type': JsonLdTypes.PRICE_SPECIFICATION;
  price: string;
  priceCurrency: 'USD';
  name: string;
}

export interface WebApplicationJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.WEB_APP;
  applicationCategory: 'BusinessApplication';
  operatingSystem: 'all';
  browserRequirements: 'Requires Javascript and HTML5 support';
  url: string;
  offers: {
    '@type': JsonLdTypes.AGGREGATE_OFFER;
    offeredBy: OrganizationJsonLd;
    highPrice: string;
    lowPrice: string;
    offerCount: string;
    priceCurrency: 'USD';
    priceSpecification: PriceSpecificationJsonLd[];
  };
  creator: OrganizationJsonLd;
}
export interface OfferJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.OFFER;
  itemCondition: JsonLdConditionTypes.NEW;
  availability: JsonLdInventoryTypes;
  url: string;
  sku: number;
  price: number;
  image: string;
  priceCurrency: string;
  priceValidUntil?: string;
}

export interface BrandJsonLd {
  '@type': JsonLdTypes.BRAND;
  name: string;
}

export interface ReviewRatingJsonLd {
  '@type': JsonLdTypes.RATING;
  ratingValue: string;
  worstRating: string;
  bestRating: string;
}

export interface ReviewAuthorJsonLd {
  '@type': JsonLdTypes.PERSON;
  name: string;
}

export interface ReviewJsonLd {
  '@type': JsonLdTypes.REVIEW;
  author: ReviewAuthorJsonLd;
  reviewBody: string;
  reviewRating: ReviewRatingJsonLd;
  datePublished: string;
}

export interface FaqAcceptedAnswerJsonLd {
  '@type': JsonLdTypes.ANSWER;
  text: string;
}

export interface FaqQuestionJsonLd {
  '@type': JsonLdTypes.QUESTION;
  name: string;
  acceptedAnswer: FaqAcceptedAnswerJsonLd;
}

export interface FaqJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.FAQ;
  mainEntity: FaqQuestionJsonLd[];
}

export interface ProductJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.PRODUCT;
  image: string;
  sku: string;
  isbn?: string;
  gtin?: string;
  mpn?: string;
  offers: OfferJsonLd | OfferJsonLd[];
  brand: BrandJsonLd;
  review?: ReviewJsonLd | ReviewJsonLd[];
  aggregateRating?: AggregateRatingJsonLd;
}

export interface OnlineBusinessJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ONLINE_BUSINESS;
  image?: string;
  url?: string;
  logo?: string;
  parentOrganization?: OrganizationJsonLd;
}

export interface ItemListJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ITEM_LIST;
  itemListElement: ListItemJsonLd[];
}

export interface ListItemJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.LIST_ITEM;
  position: number;
  item: ProductJsonLd | OnlineBusinessJsonLd | BreadcrumbJsonLd;
}

export interface AggregateRatingJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.AGGREGATE_RATING;
  ratingValue: string;
  reviewCount: string;
}

export interface BreadcrumbJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.THING;
  id: string;
  name: string;
}

export interface BreadcrumbListJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.BREADCRUMB_LIST;
  itemListElement: ListItemJsonLd[];
}
