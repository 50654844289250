interface CreatorInvoiceItemMeta {
  recommId?: string;
  notes?: string;
  artwork?: {
    front: string;
    back?: string;
  };
}

export enum CreatorInvoiceItemType {
  PRODUCT = 'PRODUCT',
  PACKAGING = 'PACKAGING',
  SERVICES = 'SERVICES',
  SHIPPING = 'SHIPPING',
  SUPPLIER_OTHER = 'SUPPLIER_OTHER',
  FBP_ASSEMBLY = 'FBP_ASSEMBLY',
  FBP_PHOTOGRAPHY = 'FBP_PHOTOGRAPHY',
  FBP_STORAGE = 'FBP_STORAGE',
  FBP_OTHER = 'FBP_OTHER',
}

export interface CreatorInvoiceItem {
  id: number;
  catalogItemId: number;
  printfulTemplateId?: string;
  printfulVariantId?: string;
  printfulProductId?: string;
  printfulMockupTaskKey?: string;
  name: string;
  perUnitPrice: number;
  quantity: number;
  subtotal?: number;
  subLogisticsFee?: number;
  logisticsFeeRate?: number;
  type: CreatorInvoiceItemType;
  images: string[];
  numProductionDays?: number;
  invoiceId?: number;
  variant1?: string;
  variant2?: string;
  variant3?: string;
  expiration?: Date;
  receivedByPietra?: boolean;
  receivedQuantity?: number;
  receivedPhotos?: [];
  receivedAt?: string;
  notes?: string;
  forceNewItem?: boolean;
  meta?: CreatorInvoiceItemMeta;
  isArchived?: boolean;
}
